import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { User } from "src/models/user.model";
import { getToken } from "src/services/auth";
import { Challenge } from "src/models/challenge.model";
import { ChallengesApi } from "src/services/challengesApi";

export interface GetCoopChallengesOptions {
    maxDate?: number
    minDate?: number
    state?: Challenge
    companyId?: string
}

export const challengesApi = createApi({
    reducerPath: 'companiesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders(headers) {
            headers.set("Authorization", getToken());
            return headers
        },
    }),
    tagTypes: ['challenges'],
    keepUnusedDataFor: 0,
    endpoints(builder) {
        return {
            getChallenges: builder.query<Challenge[], Partial<{ filters?: GetCoopChallengesOptions }>>({
                queryFn: async ({filters = null}) => {
                    const challenges = await ChallengesApi.getCoopChallenges({filters})
                    return {data: challenges}
                },
                providesTags: ['challenges'],
            }),
            createChallenge: builder.mutation<void, { challenge: Challenge }>({
                query: ({ challenge }) => ({
                    url: `/v1/coop-challenges`,
                    method: 'POST',
                    body: {challenge}
                }),
                invalidatesTags: ['challenges'],
            })
          
        }
    }
})

export const { useGetChallengesQuery } = challengesApi
