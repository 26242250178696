export const getToken = () => {
    try {
        const token = localStorage.getItem('AuthToken')
        return token
    } catch (error) {
        console.log(error)
        return null
    }
}

export const setToken = (token: string) => {
    try {
        localStorage.setItem('AuthToken', token)
        
    } catch (error) {
        console.log(error)
        return null
    }
}