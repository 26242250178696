
import { Metrics } from "src/models/metrics";
import { A3Server } from "../utils/fetch";


export class MetricsApi {


    static get = async (companyId: string) => {

        const a3Server = new A3Server();

        const metrics = await a3Server.get<Metrics>(
            `v1/admin/metrics/${companyId}`

        );
        return metrics

    };


};
