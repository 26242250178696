import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { openModal } from 'src/store/slices/modal/modal.slice';
import { CustomersEdit } from './CustomersEdit';
import { companyApiSlice, useGetCompaniesQuery } from 'src/store/slices/companies/companies.slice';

const columns: GridColDef[] = [

    { field: 'name', headerName: `Company`, width: 250 },
    { field: 'owner', headerName: `Owner`, width: 250 },
    { field: 'code', headerName: `Code`, width: 250 },
];

export const Customers = () => {
    const dispatch = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [rows, setRows] = useState([]);
    const { data: companiesDB = [], isFetching: isLoadingCompanies, refetch } = useGetCompaniesQuery()

    const newCompany = () => {
        dispatch(
            openModal({
                children: <CustomersEdit />,
                height: '65%',
                onClose: () => { 
                    refetch() 
                    dispatch(
                        companyApiSlice.util.invalidateTags(['companies'])
                    );
                }
            })
        );
    };
    const editCompany = () => {
        const companyId = selectedRows.length === 1 ? (selectedRows[0] as string) : null;
        dispatch(openModal({
            height: '65%',
            children: <CustomersEdit companyId={companyId} />,
            onClose: () => { refetch() }
        }));
    };

    useEffect(() => {
        if (isLoadingCompanies) return
        setRows(
            companiesDB.map((c) => ({
                id: c.id,
                name: c.name,
                owner: c.ownersEmail,
                code: c.code,
            }))
        );
    }, [isLoadingCompanies, companiesDB]);

    return (
        <Grid container direction="column" p={2}>
            <Grid item container direction="row" justifyContent="flex-start" pt={2}>
                <Typography variant="h3">Customers</Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end" pt={2}>
                <Button variant="contained" color="primary" onClick={() => newCompany()}>
                    New
                </Button>
                {selectedRows.length === 1 && (
                    <Button variant="contained" color="secondary" onClick={() => editCompany()} sx={{ ml: 2 }}>
                        Edit
                    </Button>
                )}
            </Grid>
            <Grid item pt={2}>
                <Card style={{ height: 'calc(100vh - 280px)', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        onSelectionModelChange={setSelectedRows}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
