import { ParticipantStatusType } from "../types/misc";
import { User } from "./user.model";

export class ChallengeParticipant extends User {


    public status: ParticipantStatusType = ParticipantStatusType.invited
    
    constructor(obj?:Partial<ChallengeParticipant>) {
        
        super(obj)

        this.status = obj?.status || this.status

    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }
}