import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import ReactDOM from 'react-dom';
import { closeSnackbar } from 'src/store/slices/snackbar/snackbar.slice';
import { blue } from '@mui/material/colors';

export const A3Snackbar = () => {
    const { isOpen, onClose, body, type = 'success', timeout = 6000 } = useAppSelector((s) => s.snackbar.snackbarOptions);

    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        if(onClose) onClose()
        dispatch(closeSnackbar());
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const content = (
        <Snackbar open={open} autoHideDuration={timeout} onClose={handleClose} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%', color: `${type}.contrastText`, backgroundColor: `${type}.dark` }}>
                {body}
            </Alert>
        </Snackbar>
    );
    return ReactDOM.createPortal(content, document.getElementById('snackbar'));
};
