import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridRowsProp, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { openModal } from 'src/store/slices/modal/modal.slice';
import { KpiEdit } from './KpisEdit';
import { useGetKpiDataQuery } from 'src/store/slices/kpis/kpis.slice';
import { Kpi, KpiData } from 'src/models/kpi.model';
import { KpiAddData } from './KpisAddData';
import { Navigate, useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { KpisApi } from 'src/services/kpiApi';
import moment from 'moment';
import { hideCompanySelector, showCompanySelector } from 'src/store/slices/companies/companies.slice';
import { setGoBackPath } from 'src/store/slices/app/app.slice';
import { A3Option } from 'src/models/types';

export const KpisViewData = () => {
    const { kpiId } = useParams();
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [currentKpi, setCurrentKpi] = useState<Kpi>(null);
    const companyId = useAppSelector((s) => s.company.currentCompany);
    
    const {
        data: kpiDataDB = [],
        isFetching: isLoadingKpiData,
        refetch: refetchKpiData
    } = useGetKpiDataQuery({ kpiId: kpiId }, { skip: !companyId || !kpiId });
    const columns: GridColDef[] = [
        { field: 'date', headerName: `Date`, width: 250 },
        { field: 'value', headerName: `Value`, width: 150 },
        {
            field: 'actions',
            headerName: `Actions`,
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => {
                                // deleteData(params.value);
                            }}
                        >
                            Delete
                        </Button>
                    </>
                );
            }
        }
    ];

    
    const addData = () => {
        dispatch(
            openModal({
                height: '65%',
                children: <KpiAddData kpiId={kpiId} />,
                onClose: () => {
                    refetchKpiData();
                }
            })
        );
    };

    const getKpi = async () => {
        const kpiDB = await KpisApi.getById(kpiId);
        setCurrentKpi(kpiDB)
    };
    useEffect(()=>{
        if(isLoadingKpiData) return 
        if(!kpiDataDB?.length) return 
        setRows(
            kpiDataDB.map(d=>{
                return {
                    value: d.value,
                    id: d.id,
                    date: moment( d.date).format('MMMM DD, yyyy'),
                }
            })
        )
    },[kpiDataDB?.length])
    useEffect(() => {
        if(companyId) getKpi()
    }, [kpiId, companyId]);

    useEffect(() => {
        dispatch(hideCompanySelector())
        dispatch(setGoBackPath('/admin/kpis'))
        return () => {
            dispatch(showCompanySelector())
            dispatch(setGoBackPath(''))
        }
    }, []);


    return (
        <Grid container direction="column" p={2}>
            <Grid item container direction="row" justifyContent="flex-start" pt={2}>
                <Typography variant="h3">Data for {currentKpi?.kpi}</Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end" pt={2}>
                <Button variant="contained" color="primary" onClick={() => addData()}>
                    Add New 
                </Button>
            </Grid>
            <Grid item pt={2}>
                <Card style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
