import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { openModal } from 'src/store/slices/modal/modal.slice';
import { UsersEdit } from './UsersEdit';
import { useGetCompaniesQuery } from 'src/store/slices/companies/companies.slice';
import { useGetUsersQuery } from 'src/store/slices/adminUsers/adminUsers.slice';
import { Company } from 'src/models/company.model';

const columns: GridColDef[] = [

    { field: 'name', headerName: `Name`, width: 250 },
    { field: 'email', headerName: `Email`, width: 200 },
    { field: 'role', headerName: `Role`, width: 70 },
    { field: 'company', headerName: `Company`, width: 250 },
];

export const Users = () => {
    const dispatch = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [rows, setRows] = useState([]);
    const [companies, setCompanies] = useState<Company[]>([]);

    const { data: usersDB = [], isFetching: isLoadingUsers, refetch: refetchUsers } = useGetUsersQuery()
    const { data: companiesDB = [], isFetching: isLoadingCompanies, refetch: refetchCompanies } = useGetCompaniesQuery(null, { skip: !usersDB })

    const newCompany = () => {
        dispatch(
            openModal({
                children: <UsersEdit />,
                height: '65%',
                onClose: () => { 
                    refetchUsers() 
                    refetchCompanies()
                }

            })
        );
    };
    const editUser = () => {
        const userId = selectedRows.length === 1 ? (selectedRows[0] as string) : null;
        dispatch(openModal({
            height: '65%',
            children: <UsersEdit userId={userId} />,
            onClose: () => { 
                refetchUsers() 
                refetchCompanies()
            }
        }));
    };



    useEffect(() => {
        if (isLoadingUsers) return
        setRows(
            usersDB.map((u) => {
                return {
                    id: u.id,
                    name: `${u.firstName} ${u.lastName}`,
                    email: u.email,
                    role: u.role,
                    company: companies[u.companyId]?.name,
                }
            })
        );
    }, [isLoadingUsers, usersDB, companies]);

    useEffect(() => {
        if (companiesDB.length) {
            const mappedCompanies = []
            companiesDB.forEach(c => {
                mappedCompanies[c?.id] = c
            })
            setCompanies(mappedCompanies)

        }
    }, [companiesDB, isLoadingCompanies])

    return (
        <Grid container direction="column" p={2}>
            <Grid item container direction="row" justifyContent="flex-start" pt={2}>
                <Typography variant="h3">Admin Users</Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end" pt={2}>
                <Button variant="contained" color="primary" onClick={() => newCompany()}>
                    New
                </Button>
                {selectedRows.length === 1 && (
                    <Button variant="contained" color="secondary" onClick={() => editUser()} sx={{ ml: 2 }}>
                        Edit
                    </Button>
                )}
            </Grid>
            <Grid item pt={2}>
                <Card style={{ height: 'calc(100vh - 310px)', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        onSelectionModelChange={setSelectedRows}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
