export enum UserRole {
    ADMIN = 'admin',
    USER = 'user',
    A3 = 'a3',
}

export const UserRoleLabels = {
    [UserRole.ADMIN]: 'Administrator',
    [UserRole.USER]: 'App User',
    [UserRole.A3]: 'A3 User',
}

export class User {

    id: string = '';
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    imgUrl: string = '';
    firebaseUserId: string = '';
    username: string = ''
    birthday: Date = new Date()
    pronouns: string[] = []
    userPreferences: string[] = []
    friends: Partial<User>[] = []
    role: UserRole;
    phone: string;
    createdAt: number;
    deletedAt: number;
    modifiedAt: number;
    employeeCode: string = ''
    companyId: string = ''
    constructor( obj?: Partial<User> ) {
        this.id = obj?.id || this.id
        this.email = obj?.email || this.email
        this.firstName = obj?.firstName || this.firstName
        this.lastName = obj?.lastName || this.lastName
        this.imgUrl = obj?.imgUrl || this.imgUrl
        this.firebaseUserId = obj?.firebaseUserId || this.firebaseUserId
        this.username = obj?.username?.trim().toLowerCase() || this.username?.trim().toLowerCase()
        this.birthday = obj?.birthday || this.birthday
        this.pronouns = obj?.pronouns || this.pronouns
        this.userPreferences = obj?.userPreferences || this.userPreferences
        this.createdAt = obj?.createdAt || null
        this.deletedAt = obj?.deletedAt || null
        this.modifiedAt = obj?.modifiedAt || null
        this.employeeCode = obj?.employeeCode || this.employeeCode
        this.companyId = obj?.companyId || this.companyId
    }

    getPublicData(){
        return new User({
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            imgUrl: this.imgUrl,
            username: this.username.trim().toLowerCase(),
        })
    }

    addFriend( user: Partial<User> ) {
        this.friends.push( {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            imgUrl: user.imgUrl,
            username: user?.username?.trim().toLowerCase(),
        } )
    }
    
    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }
}