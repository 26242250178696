import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Controller, Control, FieldValues } from 'react-hook-form';
import { A3Option } from 'src/models/types';


type Props = {
	control: Control<any, unknown>;
	name: string;
	defaultValue?: string;
	label?: string;
	noOptionsAvailable?: string;
	placeholder?: string;
	options: A3Option[];
};

export const A3Select = ({
	control,
	name,
	defaultValue = '',
	label = '',
	placeholder = '',
	options = [],
	noOptionsAvailable = '',
}: Props) => {
	if (!name) console.error('A3Select: you have to set a unique name');
	if (options.length === 0 || noOptionsAvailable)
		options = [
			{
				value: '',
				label: noOptionsAvailable || 'No options available',
			},
		];
	return (
		<Controller
			control={control}
			defaultValue={!!options.length ? defaultValue : ''}
			name={name}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<>
					<Grid item container direction="column" sx={{ width: '100%' }}>
						<FormControl sx={{ width: '100%' }}>
							<InputLabel id={label}>{label}</InputLabel>

							<Select
								sx={{ width: '100%' }}
								id={name}
								onChange={(e) => {
									onChange(e);
								}}
								value={value}
								label={label}
								labelId={label}
								placeholder={placeholder || label}
							>
								{options.map((option, i) => {
									return (
										<MenuItem key={i} value={option.value as string}>
											{option.label}
										</MenuItem>
									);
								})}
							</Select>
							{error && (
								<Typography color="error" variant="caption">
									{error?.message}
								</Typography>
							)}
						</FormControl>
					</Grid>
				</>
			)}
		/>
	);
};
