import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridRowsProp, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { openModal } from 'src/store/slices/modal/modal.slice';
import { KpiEdit } from './KpisEdit';
import { setKpi, useGetKpisQuery } from 'src/store/slices/kpis/kpis.slice';
import { Kpi } from 'src/models/kpi.model';
import { KpiAddData } from './KpisAddData';
import { useNavigate } from 'react-router';
import { KpisApi } from 'src/services/kpiApi';

export const Kpis = () => {
    const dispatch = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [rows, setRows] = useState<GridRowsProp[]>([]);
    const [kpis, setKpis] = useState<Kpi[]>([]);
    const navigate = useNavigate();

    const companyId = useAppSelector((s) => s.company.currentCompany);

    const { data: kpisDB = [], isFetching: isLoadingKpis, refetch: refetchKpis } = useGetKpisQuery(null, { skip: !companyId });
    const columns: GridColDef[] = [
        { field: 'kpi', headerName: `Name`, width: 250 },
        { field: 'previous', headerName: `Previous Value`, width: 150 },
        { field: 'current', headerName: `Current Value`, width: 150 },
        {
            field: 'addData',
            headerName: `Actions`,
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button
                            variant="text"
                            onClick={() => {
                                addData(params.value);
                            }}
                        >
                            Add
                        </Button>
                        <Button
                            variant="text"
                            onClick={() => {
                                navigate(`/admin/kpis/data/${params.value}`);

                            }}
                        >
                            View
                        </Button>
                    </>
                );
            }
        }
    ];

    
    const newKpi = () => {
        dispatch(
            openModal({
                children: <KpiEdit />,
                height: '65%',
                onClose: () => {
                    refetchKpis();
                }
            })
        );
    };
    const editKpi = () => {
        const kpiId = selectedRows.length === 1 ? (selectedRows[0] as string) : null;
        dispatch(
            openModal({
                height: '65%',
                children: <KpiEdit kpiId={kpiId} />,
                onClose: () => {
                    refetchKpis();
                }
            })
        );
    };
    const addData = (kpiId: string) => {
        dispatch(
            openModal({
                height: '65%',
                children: <KpiAddData kpiId={kpiId} />,
                onClose: () => {
                    refetchKpis();
                }
            })
        );
    };

    useEffect(() => {
        if (isLoadingKpis) return;
        setRows(
            kpisDB.map((k) => {
                return {
                    id: k?.id,
                    kpi: k?.kpi,
                    current: k?.current,
                    previous: k?.previous,
                    addData: k?.id
                };
            })
        );
    }, [isLoadingKpis, kpisDB, kpis]);

    useEffect(() => {
        if (kpisDB.length) {
            setKpis(kpisDB);
        }
    }, [kpisDB, isLoadingKpis]);

    useEffect(() => {
        refetchKpis();
    }, [companyId]);

    return (
        <Grid container direction="column" p={2}>
            <Grid item container direction="row" justifyContent="flex-start" pt={2}>
                <Typography variant="h3">KPIs</Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end" pt={2}>
                <Button variant="contained" color="primary" onClick={() => newKpi()}>
                    New
                </Button>
                {selectedRows.length === 1 && (
                    <Button variant="contained" color="secondary" onClick={() => editKpi()} sx={{ ml: 2 }}>
                        Edit
                    </Button>
                )}
            </Grid>
            <Grid item pt={2}>
                <Card style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        onSelectionModelChange={setSelectedRows}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
