import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { CoopChallenges } from 'src/content/admin/coop-challenges/CoopChallenges';
import { Customers } from 'src/content/a3/customers/Customers';
import { Users } from 'src/content/a3/users/Users';
import { Kpis } from 'src/content/admin/Kpis/Kpis';
import { KpisViewData } from 'src/content/admin/Kpis/KpisViewData';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const Admin = Loader(lazy(() => import('src/content/dashboards/admin/Admin')));

export const adminRoutes = [
    {
        path: '/',
        element: <Navigate to="dashboard" replace />
    },
    {
        path: '/dashboard',
        element: <Admin />
    },
    {
        path: '/corp-challenges',
        element: <CoopChallenges />
    },
    {
        path: '/kpis',
        element: <Kpis />
    },
    {
        path: '/kpis/data/:kpiId',
        element: <KpisViewData />
    },
    {
        path: '/kpis-add',
        element: <CoopChallenges />
    }
];
