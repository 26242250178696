import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { User } from "src/models/user.model";
import { getToken } from "src/services/auth";
import { Challenge } from "src/models/challenge.model";
import { Company } from "src/models/company.model";
import { CompanyApi } from "src/services/companyApi";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Kpi, KpiData } from "src/models/kpi.model";
import { KpisApi } from "src/services/kpiApi";


interface KpiState {
    currentKpi: Partial<Kpi>,
}
let initialState: KpiState = {
    currentKpi: null
}



export const kpisSlice = createSlice({
    name: 'kpis',
    initialState,
    reducers: {
        setKpi(state, action: PayloadAction<Kpi>) {
            state.currentKpi = action.payload
        },
        unSetKpi(state) {
            state.currentKpi = null
        },
    }
})

export const kpisApiSlice = createApi({
    reducerPath: 'kpisApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders(headers) {
            headers.set("Authorization", getToken());
            return headers
        },
    }),
    tagTypes: ['kpis'],
    keepUnusedDataFor: 0,
    endpoints(builder) {
        return {
            getKpis: builder.query<Kpi[], void>({
                queryFn: async () => {
                    const kpiDB = await KpisApi.get()
                    return { data: kpiDB }
                },
                providesTags: ['kpis'],
            }),
            getKpiData: builder.query<KpiData[], { kpiId: string }>({
                queryFn: async ({ kpiId }) => {
                    const kpiDataDB = await KpisApi.getData(kpiId)
                    return { data: kpiDataDB }
                },
                providesTags: ['kpis'],
            }),

        }
    }
})

export const { useGetKpisQuery, useGetKpiDataQuery } = kpisApiSlice
export const { setKpi, unSetKpi } = kpisSlice.actions