import { Button, Grid, Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { A3Input } from 'src/components/A3Input/A3Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { closeModal, openConfirmationModal, openModal } from 'src/store/slices/modal/modal.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Company } from 'src/models/company.model';
import { CompanyApi } from 'src/services/companyApi';
import { A3ModalOptions } from 'src/components/A3Modal/A3Modal.types';
import { openSnackbar } from 'src/store/slices/snackbar/snackbar.slice';
import { A3SnackbarOptions } from 'src/components/A3Snackbar/A3Snackbar.types';

const schema = yup
    .object({
        name: yup.string().required('Set the name'),
        ownersEmail: yup.string().email('Looks like the email is not ok').required('Set the email of the owner'),
        code: yup.string().min(6, 'Set a code of at least 8 characters').required('Set a code of at least 6 characters')
    })
    .required();

type CompanyFormType = {
    name: string;
    ownersEmail: string;
    code: string;
};

type Props = {
    companyId?: string;
};

export const CustomersEdit = ({ companyId }: Props) => {
    const dispatch = useAppDispatch();
    const [company, setCompany] = useState<Company>(null);
    const { handleSubmit, control, setValue } = useForm<CompanyFormType>({
        resolver: yupResolver(schema)
    });

    const getCompany = async (companyId: string) => {
        if (!companyId) return;
        const companyDB = await CompanyApi.getById(companyId);
        if (!companyDB) return;
        setCompany(companyDB);
        setValue('name', companyDB.name);
        setValue('ownersEmail', companyDB.ownersEmail);
        setValue('code', companyDB.code);
    };

    const onSubmit = async (data: CompanyFormType) => {
        try {
            if (!companyId) {
                await CompanyApi.create({
                    name: data.name,
                    ownersEmail: data.ownersEmail,
                    code: data.code
                });
            } else {
                await CompanyApi.update({
                    ...company,
                    id: companyId,
                    name: data.name,
                    ownersEmail: data.ownersEmail,
                    code: data.code
                });
            }
        } catch (error) {
            console.log(error);
            dispatch(openSnackbar({ body: error?.message || 'There was an error', type: 'error' } as A3SnackbarOptions));
            return;
        }
        dispatch(closeModal());
        dispatch(openSnackbar({ body: 'Company created successfully', type: 'success' } as A3SnackbarOptions));
    };

    const cancel = () => {
        dispatch(closeModal());
    };
    const deleteCompany = async () => {
        await CompanyApi.delete(companyId);
        dispatch(closeModal());
        dispatch(openSnackbar({ body: 'Company deleted successfully', type: 'success' } as A3SnackbarOptions));
    };

    const askDeleteCompany = () => {
        dispatch(
            openConfirmationModal({
                height: '45%',
                width: '45%',
                title: 'Are you sure?',
                body: 'You\'re about to delete a company.',
                onSuccess: () => {deleteCompany()},
                onClose: () => {}
            })
        );
    };

    useEffect(() => {
        if (companyId) getCompany(companyId);
    }, []);

    return (
        <>
            <Grid container direction="column" p={3} pb={0}>
                <Grid item>
                    <Typography variant="h3" mb={2}>
                        {companyId ? 'Update a company' : 'Create a new company as customer'}
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid container direction="row">
                        <Grid item xs={12} container direction="column" mt={2}>
                            <A3Input label="Company Name" control={control} name="name" />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={7} container direction="column" mt={2}>
                            <A3Input label="Company's Owner's Email" control={control} name="ownersEmail" />
                        </Grid>
                        <Grid item xs={5} container direction="column" mt={2}>
                            <A3Input label="Company Code" control={control} name="code" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ position: 'relative', right: 0, bottom: '0px', p: 2 }}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        {companyId && (
                            <Button sx={{ ml: 2 }} variant="outlined" color="error" onClick={askDeleteCompany}>
                                Delete
                            </Button>
                        )}
                        <Button sx={{ ml: 2 }} variant="outlined" color="secondary" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button sx={{ ml: 2 }} variant="outlined" color="success" onClick={handleSubmit(onSubmit)}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
