import { Button, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { A3Input } from 'src/components/A3Input/A3Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { closeModal } from 'src/store/slices/modal/modal.slice';
import { useAppDispatch } from 'src/store';
import { openSnackbar } from 'src/store/slices/snackbar/snackbar.slice';
import { A3SnackbarOptions } from 'src/components/A3Snackbar/A3Snackbar.types';
import { KpisApi } from 'src/services/kpiApi';
import { Kpi } from 'src/models/kpi.model';

const schema = yup
    .object({
        kpi: yup.string().required('Set the name for the KPI')
    })
    .required();

type KpiFormType = {
    kpi: string;
};

type Props = {
    kpiId?: string;
};

export const KpiEdit = ({ kpiId }: Props) => {
    const dispatch = useAppDispatch();
    const [kpi, setKpi] = useState<Kpi>(null);
    const { handleSubmit, control, setValue } = useForm<KpiFormType>({
        resolver: yupResolver(schema)
    });

    const getKpi = async (id: string) => {
        if (!id) return;
        const kpiDB = await KpisApi.getById(id);
        if (!kpiDB) return;
        setKpi(kpiDB);
        setValue('kpi', kpiDB.kpi);
    };

    const onSubmit = async (data: KpiFormType) => {
        try {
            if (!kpiId) {
                await KpisApi.createKpi(data.kpi);
            } else {
                await KpisApi.updateKpi({
                    kpi: data.kpi,
                    id: kpi.id
                });
            }
        } catch (error) {
            console.log(error);
            dispatch(openSnackbar({ body: error?.message || 'There was an error', type: 'error' } as A3SnackbarOptions));
            return;
        }
        dispatch(closeModal());
        dispatch(openSnackbar({ body: 'KPI created successfully', type: 'success' } as A3SnackbarOptions));
    };
    const handleCancel = () => {
        dispatch(closeModal());
    };
    const deleteKpi = async () => {
        await KpisApi.deleteKpi(kpiId);
        dispatch(openSnackbar({ body: 'KPI deleted successfully', type: 'success' } as A3SnackbarOptions));
    };

    useEffect(() => {
        if (kpiId) getKpi(kpiId);
    }, []);

    return (
        <>
            <Grid container direction="column" p={3} pb={0}>
                <Grid item>
                    <Typography variant="h3" mb={2}>
                        {kpiId ? 'Update KPI' : 'Create a new KPI'}
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} container direction="column" mt={2}>
                            <A3Input label="KPI" control={control} name="kpi" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ position: 'relative', right: 0, bottom: '0px', p: 2 }}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        
                        { kpiId && <Button sx={{ ml: 2 }} variant="outlined" color="error" onClick={deleteKpi}>
                            Delete
                        </Button>}
                        <Button sx={{ ml: 2 }} variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button sx={{ ml: 2 }} variant="outlined" color="success" onClick={handleSubmit(onSubmit)}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
