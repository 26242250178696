import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridValueGetterParams } from '@mui/x-data-grid';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { ChallengesApi } from 'src/services/challengesApi';
import { useAppDispatch, useAppSelector } from 'src/store';
import { openModal } from 'src/store/slices/modal/modal.slice';
import { CoopChallengesEdit } from './CoopChallengesEdit';
import { useGetChallengesQuery } from 'src/store/slices/challenges/challenges.slice';

const columns: GridColDef[] = [

    { field: 'name', headerName: `Challenge's Name`, width: 250 },
    { field: 'startEnd', headerName: `Period`, width: 250 },

    {
        field: 'category',
        headerName: `Category`,

        width: 200
    },
    {
        field: 'challengeActivity',
        headerName: 'Activity',
        width: 250,
        renderCell(params) {
            return params.row.challengeActivity;
        }
    },
    { field: 'participants', headerName: `Participants`, width: 200 }
];
export const CoopChallenges = () => {
    const currentCompany = useAppSelector(s=> s.company.currentCompany)
    const dispatch = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const [rows, setRows] = useState([]);
    const {data:challengesDB = [], isFetching: isLoadingChallenges, refetch, isError, error: challengesErrors } = useGetChallengesQuery({filters: {companyId:currentCompany?.id }}, {skip: !currentCompany?.id})

    const newChallenge = () => {
        dispatch(
            openModal({
                children: <CoopChallengesEdit />,
                height: '65%',
            })
        );
    };
    const editChallenge = () => {
        const challengeId = selectedRows.length === 1 ? (selectedRows[0] as string) : null;
        dispatch(
            openModal({
                height: '65%',
                children: <CoopChallengesEdit challengeId={challengeId} />,
                onClose: ()=>{refetch()}
                

            })
        );
    };

    useLayoutEffect(() => {
        if(isError) {
            console.log(challengesErrors)
            
        }
        if(isLoadingChallenges) return
        setRows(
            challengesDB.map((c) => ({
                id: c.id,
                name: c.name,
                startEnd: `${moment(c.startsAt).format('MMM DD YYYY')} to ${moment(c.endsAt).format('MMM DD YYYY')}`,
                category: c.challengeCategory.name,
                participants: c.participants?.length || 0,
                challengeActivity: c.challengeActivity.name
            }))
        );
    }, [isLoadingChallenges, challengesDB, isError]);

    return (
        <Grid container direction="column" p={2}>
            <Grid item container direction="row" justifyContent="flex-start" pt={2}>
                <Typography variant="h3">Cooperative Challanges</Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end" pt={2}>
                <Button variant="contained" color="primary" onClick={() => newChallenge()}>
                    New
                </Button>
                {selectedRows.length === 1 && (
                    <Button variant="contained" color="secondary" onClick={() => editChallenge()} sx={{ ml: 2 }}>
                        Edit
                    </Button>
                )}
            </Grid>
            <Grid item pt={2}>
                <Card style={{ height: 'calc(100vh - 310px)', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        onSelectionModelChange={setSelectedRows}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
