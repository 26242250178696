import { ChallengeActivity } from "./challengeActivity.model";
import { ChallengeCategory } from "./challengeCategory.model";
import { ChallengeParticipant } from "./challengeParticipant.model";
import { CategoryIntervalsTypes, ChallengeTypes, ParticipantStatusType } from "../types/misc";
import { User } from "./user.model";
import moment from 'moment'

export class Challenge {

    id: string = '';
    creatorId: string = '';
    name: string = '';
    challengeCategory: ChallengeCategory = new ChallengeCategory();
    challengeActivity: ChallengeActivity = new ChallengeActivity();
    interval: CategoryIntervalsTypes = '';
    quantity: number = 0;
    unit: string = '';
    companyId: string = '';

    type: ChallengeTypes = 'Public';
    isProgressive: boolean = false
    isCooperative: boolean = false
    participants: Partial<ChallengeParticipant>[] = [];
    amountParticipants: number = 0;
    startsAt: number = moment().add(1, 'day').startOf('day').toDate().getTime();
    endsAt: number = moment().add(1, 'day').add(1, 'month').endOf('day').toDate().getTime();
    createdAt: number;
    deletedAt: number;
    modifiedAt: number;

    constructor(obj?: Partial<Challenge>) {

        this.id = obj?.id || this.id
        this.creatorId = obj?.creatorId || this.creatorId
        this.name = obj?.name || this.name
        this.challengeCategory = obj?.challengeCategory || this.challengeCategory
        this.challengeActivity = obj?.challengeActivity || this.challengeActivity
        this.interval = obj?.interval || this.interval

        this.quantity = Number(obj?.quantity) || Number(this.quantity)
        this.unit = obj?.unit || this.unit
        this.companyId = obj?.companyId || this.companyId
        this.type = obj?.type || this.type
        this.isProgressive = obj?.isProgressive || this.isProgressive
        this.isCooperative = obj?.isCooperative || this.isCooperative

        this.amountParticipants = obj?.amountParticipants || this.amountParticipants
        this.participants = obj?.participants || this.participants

        this.startsAt = obj?.startsAt ||  this.startsAt
        this.endsAt = obj?.endsAt ||  this.endsAt

        this.createdAt = obj?.createdAt || null
        this.deletedAt = obj?.deletedAt || null
        this.modifiedAt = obj?.modifiedAt || null
    }

    getCreator(): Partial<ChallengeParticipant> {
        let creator = this.participants.filter(p => p.id === this.creatorId)
        return creator[0]
    }

    isParticipant(userId: string) {
        let exists = this.participants.filter(p => p.id === userId)
        return exists.length > 0
    }
    
    getParticipant(userId: string) {
        let exists = this.participants.filter(p => p.id === userId)
        return new ChallengeParticipant(exists[0])

    }
    addParticipant(user: User, status: ParticipantStatusType = ParticipantStatusType.invited) {
        this.amountParticipants++
        const participant = {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            imgUrl: user.imgUrl,
            username: user.username,
            status,
        }
        this.participants.push(participant)
        return participant
    }

    removeParticipant(user: User) {
        let removedParticipant: Partial<ChallengeParticipant> = {}
        let newParticipantsList = this.participants.filter((p: Partial<ChallengeParticipant>) => {
            if (p.id === user.id) {
                this.amountParticipants--
                removedParticipant = p
                return false
            }
            return true
        })
        this.participants = newParticipantsList
        return removedParticipant
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse(JSON.stringify(this));
        return plainObj;
    }
    isEditable() {

        if (this.amountParticipants > 1) return false

        if (this.getHasEnded()) return false

        if (this.getHasStarted()) return false

        return true

    }

    getChallangeTime() {

        const timeDiff = (moment(this.endsAt).diff(this.startsAt, 'milliseconds')) + 1

        return timeDiff
    }
    

    getChallengeType() {

        if (this.challengeCategory.name === this.challengeActivity?.name) return this.challengeCategory.name

        return this.challengeCategory.name + (
            this.challengeActivity?.name
                ? ' - ' + this.challengeActivity?.name
                : '')
    }

    getHasStarted() {
        return moment().isAfter(moment(this.startsAt))
    }
    getHasEnded() {
        return moment().isAfter(moment(this.endsAt))
    }
}