import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "src/services/auth";

export const branchesApiSlice = createApi({
    reducerPath: "branchesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders(headers) {
            headers.set("Authorization", getToken());
            return headers;
        }
    }),
    tagTypes: ["Branches"],
    endpoints(builder) {
        return {
            getBranches: builder.query({
                query: () => ({
                    url: "/v1/branches",
                    method: "GET"
                }),
                providesTags: ["Branches"]
            }),
            createBranch: builder.mutation({
                query: ({ branch }) => ({
                    url: "/v1/branch",
                    method: "POST",
                    body: { branch }
                }),
                invalidatesTags: ["Branches"]
            }),
            updateBranch: builder.mutation({
                query: ({ branch }) => ({
                    url: "/v1/branch",
                    method: "PUT",
                    body: { branch }
                }),
                invalidatesTags: ["Branches"]
            }),
            deleteBranch: builder.mutation({
                query: ({ id }) => ({
                    url: `/v1/branch/${id}`,
                    method: "DELETE"
                }),
                invalidatesTags: ["Branches"]
            })
        };
    }
})

export const { useGetBranchesQuery, useCreateBranchMutation, useUpdateBranchMutation, useDeleteBranchMutation } = branchesApiSlice;