/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import { closeConfirmationModal, closeModal } from 'src/store/slices/modal/modal.slice';

type Props = {
    type?: 'modal' | 'confirmation';
};
export const A3Modal = ({ type = 'modal' }: Props) => {
    const typeOfModalOptions = type === 'modal' ? 'modalOptions' : 'confirmationModalOptions';

    const {
        isOpen = false,
        onClose = () => {},
        onSuccess,
        title,
        body,
        children,
        width,
        height
    } = useAppSelector((s) => s.modal[typeOfModalOptions]);

    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const closeCurrentModal = () => {
        type === 'modal' ? dispatch(closeModal()) : dispatch(closeConfirmationModal());
    };
    const handleClose = () => {
        onClose('');
        closeCurrentModal();
        setOpen(false);
    };

    const handleOk = () => {
        onSuccess && onSuccess('ok');
        onClose('');
        closeCurrentModal();
    };
    const handleCancel = () => {
        onClose('');
        closeCurrentModal();
    };

    const style = {
        position: 'absolute' as 'absolute',
        overflowY: 'auto',
        overflowX: 'hidden',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width || (children ? '70%' : '300px'),
        height: height || (children ? '50%' : '400px'),
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        p: 0
    };

    useEffect(() => {
        setOpen(isOpen);
        return () => onClose('ok');
    }, [isOpen]);

    const content = (
        <Modal open={open} onClose={handleClose}>
            <>
                {children && <Box sx={style}>{children}</Box>}
                {!children && (
                    <Grid container direction="column" sx={{ ...style, p: 3 }}>
                        <Typography color="InfoText" variant="h2">
                            {title}
                        </Typography>
                        <Typography color="InfoText" mt={1} variant="body1">
                            {body}
                        </Typography>
                        <Grid
                            item
                            container
                            direction="column"
                            alignContent="stretch"
                            sx={{
                                position: 'absolute',
                                bottom: '8px',
                                left: 0,
                                p: 2
                            }}
                        >
                            <Button variant="outlined" color="primary" onClick={() => handleOk()} sx={{ mb: 2 }}>
                                Ok
                            </Button>
                            <Button variant="outlined" color="error" onClick={() => handleCancel()}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </>
        </Modal>
    );
    return ReactDOM.createPortal(content, document.getElementById(type || 'modal'));
};
