import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { Customers } from 'src/content/a3/customers/Customers';
import { Users } from 'src/content/a3/users/Users';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const Admin = Loader(lazy(() => import('src/content/dashboards/admin/Admin')));
const Test = Loader(lazy(() => import('src/content/blocks/ListsSmall/index')));

export const a3Routes = [
    {
        path: '/',
        element: <Navigate to="/a3/dashboard" replace />
    },

    {
        path: '/dashboard',
        element: <Admin />
    },
    {
        path: '/test',
        element: <Test />
    },
    {
        path: '/customers',
        element: <Customers />
    },
    {
        path: '/users',
        element: <Users />
    }
];


