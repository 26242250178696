
import { Friend } from 'src/models/app-models/friend.model';
import { User } from '../models/user.model';
import { uploadFileFirebase } from '../providers/firebase';
import { logError } from '../utils/error';
import { A3Server } from '../utils/fetch';



export class AdminUsersApi {

    static get = async (): Promise<User> => {

        try {
            const a3Server = new A3Server()

            const userDB = await a3Server.get<User>(`v1/admin-users/`)

            return new User(userDB)
        } catch (error) {
            logError('AdminUsersApi:get', error)
            throw error;
        }
    };
    static getById = async (userId: string): Promise<User> => {

        try {
            const a3Server = new A3Server()

            const userDB = await a3Server.get<User>(`v1/admin-users/${userId}`)

            return new User(userDB)
        } catch (error) {
            logError('AdminUsersApi:getById', error)
            throw error;
        }
    };

    static create = async (user: Partial<User>, password: string) => {
        if (!user) {
            console.error('No user...', user);
            throw new Error('No user...');
        }
        try {

            const a3Server = new A3Server()

            const userDB = await a3Server.post<User>(`v1/admin-user`, { user, password })

            return userDB

        } catch (error) {
            logError('AdminUsersApi:create', error)
            throw error;
        }
    };

    static update = async (user: Partial<User>) => {
        if (!user) {
            console.error('No user...', user);
            throw new Error('No user...');
        }

        try {

            const a3Server = new A3Server()
            const userDB = await a3Server.put<User>(`v1/admin-users/${user.id}`, { user })
            return userDB

        } catch (error) {
            logError('AdminUsersApi:create', error)
            throw error;
        }
    };
    static delete = async (userId: string) => {
        if (!userId) {
            console.error('No userId...', userId);
            throw new Error('No userId...');
        }

        try {

            const a3Server = new A3Server()
            return  await a3Server.delete<void>(`v1/admin-users/${userId}`)
            

        } catch (error) {
            logError('AdminUsersApi:create', error)
            throw error;
        }
    };

}
