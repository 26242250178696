
import { Task } from "src/models/app-models/task.model";
import store from "src/store";

import { A3Server } from "../utils/fetch";
import { Company } from "src/models/company.model";

export class CompanyApi {



    static create = async (
        company: Partial<Company>,
    ) => {

        const a3Server = new A3Server();

        const companyDB = await a3Server.post<Company>(
            `v1/admin/companies`,
            company
        );

        return companyDB;

    };
    static get = async () => {

        const a3Server = new A3Server();

        const companies = await a3Server.get<Company[]>(
            `v1/admin/companies`

        );
        return companies;

    };
    static getById = async (id: string) => {

        const a3Server = new A3Server();

        const companies = await a3Server.get<Company>(
            `v1/admin/companies/${id}`

        );
        return companies;

    };
    static update = async (
        company: Partial<Company>,
    ) => {

        const a3Server = new A3Server();

        const companyDB = await a3Server.put<Company>(
            `v1/admin/companies`, company

        );
        return companyDB;

    };
    static delete = async (id: string) => {

        const a3Server = new A3Server();

        return a3Server.delete<void>(
            `v1/admin/companies/${id}`

        );
        

    };



};
