
import { Task } from "src/models/app-models/task.model";
import { Kpi, KpiData } from "src/models/kpi.model";
import store from "src/store";

import { A3Server } from "../utils/fetch";

export class KpisApi {

    // post 
    // delete /v1/companies/${companyId}/kpis/${kpiId}

    static getData = async (kpiId: string) => {
        const companyId = store.getState().company.currentCompany.id;

        try {
            const a3Server = new A3Server();

            const kpiDataDb = await a3Server.get<KpiData[]>(`v1/companies/${companyId}/kpis/${kpiId}/data`);

            return kpiDataDb;
        } catch (error) {
            throw error;
        }
    };
    static getById = async (kpiId: string) => {
        const companyId = store.getState().company.currentCompany.id;

        try {
            const a3Server = new A3Server();

            const kpiDb = await a3Server.get<Kpi>(`v1/companies/${companyId}/kpis/${kpiId}`);

            return kpiDb;
        } catch (error) {
            throw error;
        }
    };
    static get = async () => {
        const companyId = store.getState().company.currentCompany.id;

        try {
            const a3Server = new A3Server();

            const kpisDb = await a3Server.get<Kpi[]>(`v1/companies/${companyId}/kpis`);

            return kpisDb;
        } catch (error) {
            throw error;
        }
    };

    static createKpi = async (kpiName: string) => {

        try {
            const companyId = store.getState().company.currentCompany.id;
            const a3Server = new A3Server();
            await a3Server.post(`v1/companies/${companyId}/kpis`, { kpi: kpiName });

        } catch (error) {
            throw error;
        }
    };
    static updateKpi = async (kpi: Kpi) => {

        try {
            const companyId = store.getState().company.currentCompany.id;
            const a3Server = new A3Server();
            await a3Server.put(`v1/companies/${companyId}/kpis/${kpi.id}`, kpi);

        } catch (error) {
            throw error;
        }
    };

    static addKpiData = async (kpiData: KpiData, kpiId: string) => {

        try {
            const companyId = store.getState().company.currentCompany.id;

            const a3Server = new A3Server();

            await a3Server.post(`v1/companies/${companyId}/kpis/${kpiId}/data`, kpiData);


        } catch (error) {
            throw error;
        }
    };
    static deleteKpiData = async (kpiId: string, dataId: string) => {

        try {

            const companyId = store.getState().company.currentCompany.id;
            const a3Server = new A3Server();
            a3Server.delete(`v1/companies/${companyId}/kpis/${kpiId}/data/${dataId}`);

        } catch (error) {
            throw error;
        }
    };

    static deleteKpi = async (kpiId: string) => {

        try {
            const companyId = store.getState().company.currentCompany.id;
            const a3Server = new A3Server();
            await a3Server.delete(`v1/companies/${companyId}/kpis/${kpiId}`);

        } catch (error) {
            throw error;
        }
    };

};
