import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "src/models/user.model";
import { setToken } from "src/services/auth";
import { RootState } from "src/store";

interface AuthState {
    isInitialized: boolean,
    isAuthenticated: boolean,
    isError: boolean,
    error: string,
    method: string,
    user: Partial<User>,
}
interface RegisterUser {
    user: Partial<User>,
    firebaseToken: string
}

const initialState: AuthState = {
    isInitialized: true,
    isAuthenticated: false,
    isError: false,
    error: null,
    user: null,
    method: 'firebase'
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initialize(state, action: PayloadAction<Partial<AuthState>>) {
            state.isAuthenticated = true;
        },
        setUser(state, action: PayloadAction<Partial<User>>) {
            state.isAuthenticated = action.payload?.id ? true : false;
            state.user = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.isError = action.payload ? true : false;
            state.error = action.payload;
        },
        logout(state) {
            state.isAuthenticated = false;
            state.user = null;
        }
    }
})

interface SignInResponse {
    user: User,
    token: string
}

export const authApiSlice = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
    }),
    keepUnusedDataFor: 0,
    endpoints(builder) {
        return {
            signIn: builder.query<User, { firebaseToken: string }>({
                query: ({ firebaseToken }) => ({
                    url: `/v1/auth/admin/signin`,
                    method: 'GET',
                    headers: { firebaseToken }
                }),
                transformResponse: (response: SignInResponse) => {
                    setToken(response.token);
                    return response.user
                },
            }),
            register: builder.mutation<User, RegisterUser>({
                query: ({ firebaseToken, user }) => ({
                    url: `/v1/auth/email`,
                    method: 'POST',
                    body: { firebaseToken, user }
                }),
            }),
        }
    }
})

export const { useSignInQuery, useRegisterMutation } = authApiSlice
export const { initialize, setUser, setError, logout } = authSlice.actions
export default authSlice.reducer