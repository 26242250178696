import { PayloadAction, createSlice } from "@reduxjs/toolkit";


interface appState {
    goBackPath: string,
}
let initialState: appState = {
    goBackPath: '',
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setGoBackPath(state, action: PayloadAction<string>) {
            state.goBackPath = action.payload
        },
    }
})

export const { setGoBackPath } = appSlice.actions