import styled from '@emotion/styled';
import { TextField as MuiTextField, TextFieldProps, Typography } from '@mui/material';
import { Controller, Control, FieldValues } from 'react-hook-form';
type Props = {
	control: Control<any, unknown>;
	defaultValue?: string;
} & TextFieldProps;
export const A3Input = ({ control, name, defaultValue = '', label = '', placeholder = '', ...rest }: Props) => {
	const TextField = styled(MuiTextField)(() => ({}));
	if(!name) console.error('A3Input: you have to set a unique name')
	
	return (
		<Controller
			control={control}
			defaultValue={defaultValue}
			name={name}
			render={({ field: { onChange, value }, fieldState: { isDirty, isTouched, error } }) => (
				<>
					<TextField
						sx={{ width: '100%' }}
						onChange={onChange}
						error={!!error}
						value={value}
						label={label}
						placeholder={placeholder}
						{...rest}
					/>
					{error && (
						<Typography color="error" variant="caption">
							{error?.message}
						</Typography>
					)}
				</>
			)}
		/>
	);
};
