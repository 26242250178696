import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from 'src/content/pages/Auth/Login/Cover';
import { useAppSelector } from 'src/store';

interface AuthenticatedProps {
    children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
    const { children } = props;
    const auth = useAppSelector((e) => e.auth);
    const location = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
    if (!auth.isAuthenticated) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname);
        }
        return <Login />;
    }

    if (auth.user.role === 'admin' && location.pathname.indexOf('a3') > -1) {
        return <Navigate to={'admin'} replace />;
    }

    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

Authenticated.propTypes = {
    children: PropTypes.node
};

export default Authenticated;
