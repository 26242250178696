
export type PostReactionTypes = 'hearts' | 'fists' | 'likes'
export type PostReactionActionTypes = 'decrement' | 'increment'
export type ChallengeTypes = 'Public' | 'Private' 
export type ChallengeCategoryTypes = '' | 'fitness' | 'diet' | 'fasting' | 'yoga' | 'meditation' | 'reading' | 'stop-smoking' | 'stop-drinking'
export type CategoryIntervalsTypes = '' | 'daily' | 'monthly' | 'weekly'
export const CategoryIntervals: CategoryIntervalsTypes[] = [ 'daily', 'monthly', 'weekly' ]
export enum ParticipantStatusType {
    invited = 'Invited',
    rejected = 'Rejected',
    joined = 'Joined',
}
export enum FriendType {
    challenge = 'challenge',
    business = 'business',
}
export enum NotificationType {
    CHALLENGE_INVITATION = 'challengeInvitation'
}
export enum NotificationStatusType {
    READ = 'read',
    NOT_READ = 'not-read',
}