import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { User } from "src/models/user.model";
import { getToken } from "src/services/auth";

export const adminUsersSlice = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders(headers) {
            headers.set("Authorization", getToken());
            return headers
        },
    }),
    tagTypes: ['Users'],
    keepUnusedDataFor: 20,
    endpoints(builder) {
        return {
            createUser: builder.mutation<User, { user: User, password: string }>({
                query: ({ user, password }) => ({
                    url: `/v1/admin-user`,
                    method: 'POST',
                    body: { user, password }
                }),
                invalidatesTags: ['Users'],
            }),
            deleteUser: builder.mutation<User, { userId: string }>({
                query: ({ userId }) => ({
                    url: `/v1/admin-user`,
                    method: 'DELETE',
                    body: { userId }
                }),
                invalidatesTags: ['Users'],
            }),
            getUsers: builder.query<User[], void>({
                query: () => ({
                    url: `/v1/admin-users`,
                    method: 'GET'
                }),
                providesTags: ['Users'],
            })
        }
    }
})

export const { useCreateUserMutation, useGetUsersQuery, useDeleteUserMutation } = adminUsersSlice
