import { Button, Grid, Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { A3Input } from 'src/components/A3Input/A3Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { closeModal, openConfirmationModal, openModal } from 'src/store/slices/modal/modal.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Company } from 'src/models/company.model';
import { CompanyApi } from 'src/services/companyApi';
import { A3ModalOptions } from 'src/components/A3Modal/A3Modal.types';
import { openSnackbar } from 'src/store/slices/snackbar/snackbar.slice';
import { A3SnackbarOptions } from 'src/components/A3Snackbar/A3Snackbar.types';
import { A3Select } from 'src/components/A3Select/A3Select';
import { A3Option } from 'src/models/types';
import { AdminUsersApi } from 'src/services/adminUserApi';
import { User, UserRole } from 'src/models/user.model';

const schema = yup
    .object({
        firstName: yup.string().required('Set the name'),
        lastName: yup.string().required('Set the name'),
        email: yup.string().email('Looks like the email is not ok').required('Set the email of the user'),
        password: yup.string().min(8, 'Set a password of at least 8 characters').required('Set a password for the user'),
        role: yup.string().required('Set a role for the user')
    })
    .required();

type AdminUserFormType = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: string;
};

type Props = {
    userId?: string;
};

export const UsersEdit = ({ userId }: Props) => {
    const dispatch = useAppDispatch();
    const [user, setUser] = useState<User>(null);
    const { handleSubmit, control, setValue } = useForm<AdminUserFormType>({
        resolver: yupResolver(schema)
    });

    const roles: A3Option[] = [
        { label: 'A3 User', value: 'a3' },
        { label: 'Admin of a Company', value: 'admin' }
    ];

    const getUser = async (id: string) => {
        if (!id) return;
        const userDB = await AdminUsersApi.getById(id);
        if (!userDB) return;
        setUser(userDB);
        setValue('firstName', userDB.firstName);
        setValue('lastName', userDB.lastName);
        setValue('password', 'prefilled-not-a-real-password');
        setValue('email', userDB.email);
        setValue('role', userDB.role);
    };

    const onSubmit = async (data: AdminUserFormType) => {
        try {
            if (!userId) {
                await AdminUsersApi.create(
                    {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        role: data.role as UserRole
                    },
                    data.password
                );
            } else {
                await AdminUsersApi.update({
                    id: userId,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    role: data.role as UserRole
                });
            }
        } catch (error) {
            console.log(error);
            dispatch(openSnackbar({ body: error?.message || 'There was an error', type: 'error' } as A3SnackbarOptions));
            return;
        }
        dispatch(closeModal());
        dispatch(openSnackbar({ body: 'User created successfully', type: 'success' } as A3SnackbarOptions));
    };
    const handleCancel = () => {
        dispatch(closeModal());
    };
    const deleteUser = async () => {
        await AdminUsersApi.delete(userId);
        dispatch(closeModal());
        dispatch(openSnackbar({ body: 'User deleted successfully', type: 'success' } as A3SnackbarOptions));
    };

    const askDeleteUser = () => {
        dispatch(
            openConfirmationModal({
                height: '45%',
                width: '45%',
                title: 'Are you sure?',
                body: 'You\'re about to delete a user.',
                onSuccess: () => {deleteUser()},
                onClose: () => {}
            })
        );
    };

    useEffect(() => {
        if (userId) getUser(userId);
    }, []);

    return (
        <>
            <Grid container direction="column" p={3} pb={0}>
                <Grid item>
                    <Typography variant="h3" mb={2}>
                        {userId ? 'Update an Admin User' : 'Create a new Admin User'}
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6} container direction="column" mt={2}>
                            <A3Input label="First name" control={control} name="firstName" />
                        </Grid>
                        <Grid item xs={6} container direction="column" mt={2}>
                            <A3Input label="Last Name" control={control} name="lastName" />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6} container direction="column" mt={2}>
                            <A3Input label="Email" control={control} name="email" />
                        </Grid>
                        {!userId && (
                            <Grid item xs={3} container direction="column" mt={2}>
                                <A3Input label="Password" type="password" control={control} name="password" />
                            </Grid>
                        )}
                        <Grid item xs={3} container direction="column" mt={2}>
                            <A3Select control={control} name="role" label="Role" options={roles} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ position: 'relative', right: 0, bottom: '0px', p: 2 }}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        {userId && (
                            <Button sx={{ ml: 2 }} variant="outlined" color="error" onClick={askDeleteUser}>
                                Delete
                            </Button>
                        )}
                        <Button sx={{ ml: 2 }} variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button sx={{ ml: 2 }} variant="outlined" color="success" onClick={handleSubmit(onSubmit, ()=>alert('error'))}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
