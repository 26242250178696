import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { A3ModalOptions } from "src/components/A3Modal/A3Modal.types";


interface A3ModalState {
    modalOptions: Partial<A3ModalOptions>,
    confirmationModalOptions: Partial<A3ModalOptions>,
}
const initialState: A3ModalState = {
    modalOptions: {
        isOpen: false,
    },
    confirmationModalOptions: {
        isOpen: false,
    },
}

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state, action: PayloadAction<A3ModalOptions>) {
            state.modalOptions = action.payload
            state.modalOptions.isOpen = true
        },
        closeModal(state) {
            state.modalOptions = {
                ...state.modalOptions,
                isOpen: false
            };
        },
        openConfirmationModal(state, action: PayloadAction<A3ModalOptions>) {
            state.confirmationModalOptions = action.payload
            state.confirmationModalOptions.isOpen = true
        },
        closeConfirmationModal(state) {
            state.confirmationModalOptions = {
                ...initialState.confirmationModalOptions,
                isOpen: false
            };
        },
    }
})

export const { openModal, closeModal, openConfirmationModal, closeConfirmationModal } = modalSlice.actions
export default modalSlice.reducer