import { ChallengeCategoryTypes } from "../types/misc";

export class ChallengeCategory {

    public id: ChallengeCategoryTypes = '';
    public image: string = '';
    public name: string = '';
    constructor( image: string = '', id: ChallengeCategoryTypes = '', name: string = '' ) {
        this.image = image
        this.id = id
        this.name = name
    }

    toPlainObject(): any {
        const plainObj: any = JSON.parse( JSON.stringify( this ) );
        return plainObj;
    }
}