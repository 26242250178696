import { useContext, useEffect, useState } from 'react';

import { Box, alpha, Stack, lighten, Divider, IconButton, Tooltip, styled, useTheme, Button } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderUserbox from './Userbox';
import { useAppDispatch, useAppSelector } from 'src/store';
import { UserRole } from 'src/models/user.model';
import { A3Select } from 'src/components/A3Select/A3Select';
import { A3Option } from 'src/models/types';
import { useForm, useWatch } from 'react-hook-form';
import { Company } from 'src/models/company.model';
import { setCompany, useGetCompaniesQuery } from 'src/store/slices/companies/companies.slice';
import { ArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const navigate = useNavigate();
    const currentUser = useAppSelector((s) => s.auth.user);
    const currentCompany = useAppSelector((s) => s.company.currentCompany);
    const showCompanySelector = useAppSelector((s) => s.company.showCompanySelector);
    const goBackPath = useAppSelector((s) => s.app.goBackPath);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [companies, setCompanies] = useState<Company[]>([]);
    const [companiesOptions, setCompaniesOptions] = useState<A3Option[]>([]);
    const { control, watch, setValue } = useForm();
    const selectedComapny = watch('company');
    
    const { data: companiesDB = [], isFetching: isLoadingCompanies } = useGetCompaniesQuery();
    const goBack = () => {
        navigate(goBackPath);
    };

    useEffect(() => {
        if (!selectedComapny && currentCompany?.id) return setValue('company', currentCompany?.id);
        if (!selectedComapny || !Object.keys(companies).length) return;
        console.log('Selected Company: ', selectedComapny);
        dispatch(setCompany(companies[selectedComapny]));
    }, [selectedComapny, companies, currentCompany]);

    useEffect(() => {
        if (!companiesDB.length) return;

        const mappedCompanies = [];
        companiesDB.forEach((c) => {
            mappedCompanies[c?.id] = c;
        });
        setCompanies(mappedCompanies);
        setCompaniesOptions(
            companiesDB.map((c) => {
                return { label: c?.name, value: c?.id };
            })
        );
    }, [companiesDB, isLoadingCompanies]);

    if (isLoadingCompanies) return null;

    return (
        <HeaderWrapper
            display="flex"
            alignItems="center"
            sx={{
                boxShadow:
                    theme.palette.mode === 'dark'
                        ? '0 1px 0 ' +
                          alpha(lighten(theme.colors.primary.main, 0.7), 0.15) +
                          ', 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)'
                        : '0px 2px 8px -3px ' +
                          alpha(theme.colors.alpha.black[100], 0.2) +
                          ', 0px 5px 22px -4px ' +
                          alpha(theme.colors.alpha.black[100], 0.1)
            }}
        >
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} alignItems="center" spacing={2}>
                {goBackPath && (
                    <Button onClick={goBack} variant="outlined">
                        <ArrowLeft /> Return
                    </Button>
                )}
                {currentUser.role === UserRole.A3 && showCompanySelector && (
                    <A3Select
                        name="company"
                        options={companiesOptions}
                        control={control}
                        defaultValue={currentCompany?.id ?? currentUser?.companyId}
                        placeholder="Select a company"
                    />
                )}

                {/* <HeaderSearch /> */}
                {/* <HeaderMenu /> */}
            </Stack>
            <Box display="flex" alignItems="center">
                <HeaderUserbox />
                <Box
                    component="span"
                    sx={{
                        ml: 2,
                        display: { lg: 'none', xs: 'inline-block' }
                    }}
                >
                    <Tooltip arrow title="Toggle Menu">
                        <IconButton color="primary" onClick={toggleSidebar}>
                            {!sidebarToggle ? <MenuTwoToneIcon fontSize="small" /> : <CloseTwoToneIcon fontSize="small" />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </HeaderWrapper>
    );
}

export default Header;
