import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, } from "firebase/auth";
import { User } from "src/models/user.model";
import { auth, firebaseStorage } from "src/services/firebase.config";
import { ref } from "firebase/storage";
import { getDownloadURL, uploadBytes } from "firebase/storage";




export const signOutFirebase = async () => {

    await signOut(auth)


}
export const createUserFirebase = async (email: string, password: string) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        const user = userCredential.user;
        const firebaseToken = await user.getIdToken()

        const newUser: Partial<User> = {
            id: user.uid,
            email,
            firstName: user.displayName,
        }

        // const [register, { data }] = useRegisterMutation()
        // const newUserDB = await register({ firebaseToken, user: newUser })


    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        throw new Error(`Error ${errorCode}: ${errorMessage}`)
    }
}


export const signInUserFirebase = async (email: string, password: string) => {

    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        // const user = userCredential.user;

    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        throw new Error(`Error ${errorCode}: ${errorMessage}`)
    }
}

export const uploadFileFirebase = async (route: string, fileToUpload: File): Promise<string> => {
    const bucketPath = `${route}/${fileToUpload.name}`
    const fileRef = ref(firebaseStorage, bucketPath);
    await uploadBytes(fileRef, fileToUpload)
    const url = await getDownloadURL(fileRef)

    return url
}
