import { Box, styled, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoTextWrapper = styled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
`
);

const VersionBadge = styled(Box)(
  ({ theme }) => `
        background: ${theme.palette.success.main};
        color: ${theme.palette.success.contrastText};
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${theme.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`
);

const LogoText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(15)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

function LogoA3({ showBadge = true }: {showBadge?: boolean}) {
  const { t }: { t: any } = useTranslation();

  return (
    <LogoWrapper to="/overview">
      <img
        style={{ height: 48 }}
        alt="A3 Logo"
        src="/static/images/logo/logo.png"
      />
      <Box
        component="span"
        sx={{
          display: { xs: 'none', sm: 'inline-block' }
        }}
      >
        <LogoTextWrapper>
          {showBadge && (
            <Tooltip title={t('Admin Dashboard')} arrow placement="right">
              <VersionBadge>Admin Dashboard</VersionBadge>
            </Tooltip>
          )}
          <LogoText>A3 Challenges</LogoText>
        </LogoTextWrapper>
      </Box>
    </LogoWrapper>
  );
}

export default LogoA3;
