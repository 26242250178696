import { Button, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { A3Input } from 'src/components/A3Input/A3Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { closeModal } from 'src/store/slices/modal/modal.slice';
import { useAppDispatch } from 'src/store';
import { openSnackbar } from 'src/store/slices/snackbar/snackbar.slice';
import { A3SnackbarOptions } from 'src/components/A3Snackbar/A3Snackbar.types';
import { KpisApi } from 'src/services/kpiApi';
import { A3DatePicker } from 'src/components/A3DatePicker/A3DatePicker';
import { useEffect } from 'react';
import { unSetKpi } from 'src/store/slices/kpis/kpis.slice';

const schema = yup
    .object({
        value: yup.number().required('Set the value.'),
        date: yup.date().optional()
    })
    .required();

type KpiDataFormType = {
    value: number;
    date: Date;
};
type Props = {
    kpiId?: string;
};

export const KpiAddData = ({ kpiId }: Props) => {
    const dispatch = useAppDispatch();
    const { handleSubmit, control, setValue } = useForm<KpiDataFormType>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: KpiDataFormType) => {
        try {
            
            if (!kpiId) {
                dispatch(openSnackbar({ body: 'No KPI ID', type: 'error' } as A3SnackbarOptions));
                return;
            }

            await KpisApi.addKpiData({ value: data.value, date: data.date }, kpiId);
            
        } catch (error) {
            
            console.log(error);
            dispatch(openSnackbar({ body: error?.message || 'There was an error', type: 'error' } as A3SnackbarOptions));
            return;

        }
        dispatch(closeModal());
        dispatch(openSnackbar({ body: 'KPI created successfully', type: 'success' } as A3SnackbarOptions));
    };
    const handleCancel = () => {
        dispatch(closeModal());
    };
    const deleteKpiData = async () => {
        await KpisApi.deleteKpi(kpiId);
        dispatch(openSnackbar({ body: 'KPI deleted successfully', type: 'success' } as A3SnackbarOptions));
    };

    useEffect(() => {
        setValue('date', new Date())


        return () => {
            dispatch(unSetKpi())
        }
    }, []);

    return (
        <>
            <Grid container direction="column" p={3} pb={0}>
                <Grid item>
                    <Typography variant="h3" mb={2}>
                        Add KPI value
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" mb={2}>
                        Add the numeric value and the date that the value was obtained. Don't enter any units or text, the system will use this number to do calculations on the dashboard.
                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6} container direction="column" mt={2}>
                            <A3Input type="number" label="Value" control={control} name="value" />
                        </Grid>
                        <Grid item xs={6} container direction="column" mt={2}>
                            <A3DatePicker control={control} name="date" label="Date" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ position: 'relative', right: 0, bottom: '0px', p: 2 }}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <Button sx={{ ml: 2 }} variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button sx={{ ml: 2 }} variant="outlined" color="success" onClick={handleSubmit(onSubmit)}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
