import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
  Alert
} from '@mui/material';

import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { signInUserFirebase } from 'src/providers/firebase';
import { useAppSelector } from 'src/store';

const Login: FC = () => {
  
  const initialValues = {
    email: '',
    password: '', 
    terms: true,
    submit: null
  };

  const navigate = useNavigate()
  const isMountedRef = useRefMounted();
  const [isLoading, setIsLoading] = useState(false)
  const { t }: { t: any } = useTranslation();
  const { isError: isAuthError, error: authError } = useAppSelector( e => e.auth )
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ): Promise<void> => {
    try {
      setIsLoading(true)
      await signInUserFirebase(
        values.email,
        values.password
      );
      setTimeout(() => {
        setIsLoading(false)
      }, 4000);
      
    } catch (err) {
      setIsLoading(false)
      console.error(err);
      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if(isAuthError) setIsLoading(false)  
  }, [isAuthError])
  

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('The email provided should be a valid email address'))
          .max(255)
          .required(t('The email field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required')),
        terms: Yup.boolean().oneOf(
          [true],
          t('You must agree to our terms and conditions')
        )
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={t('Email address')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t('Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link component="a" href="#">
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            />
            <Link component={RouterLink} to="/account/recover-password">
              <b>{t('Lost password?')}</b>
            </Link>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
            disabled={isLoading}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('Sign in')}
          </Button>
          {errors.submit && (
            <Alert sx={{ mt: 2 }} variant="filled" severity="error">
              {errors.submit}
            </Alert>
          )}
          {isAuthError && (
            <Alert sx={{ mt: 2 }} variant="filled" severity="error">
              {authError}
            </Alert>
          )}
        </form>
      )}
    </Formik>
  );
};

export default Login;
