import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAppSelector } from 'src/store';

interface GuestProps {
  children: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {

  const { isAuthenticated } = useAppSelector((s) => s.auth);
  

  if (isAuthenticated) {
    return <Navigate to="/extended-sidebar/dashboards/reports" />;
  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};

export default Guest;
