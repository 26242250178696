import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import {  useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { A3Option } from 'src/models/types';


type Props = {
	control: Control<any, unknown>;
	name: string;
	defaultValue?: string;
	label?: string;
	placeholder?: string;
	options: A3Option[];
};

export const A3Autocomplete = ({ control, name, defaultValue = '', label = '', placeholder = '', options }: Props) => {
	if (!name) console.error('A3Autocomplete: you have to set a unique name');
	if (options.every((x) => x.value !== defaultValue))
		console.warn('A3Autocomplete: your defaultValue should be included in your options');

	const [inputValue, setInputValue] = useState('');
	const getLabelFromValue = (value = '') => {
		let label = '';
		options.map((op) => {
			if (value === op.value) label = op.label;
		});
		return label;
	};
	return (
		<Controller
			control={control}
			defaultValue={defaultValue}
			name={name}
			render={({ field: { onChange, value }, fieldState: { error, isDirty, isTouched } }) => (
				<Grid sx={{ width: '100%' }} container direction="column">
					<Autocomplete
						options={options}
						sx={{ width: '100%' }}
						id={name}
						isOptionEqualToValue={(option, value) => {
							return option?.label === value;
						}}
						onChange={(_, selectedOption) => {
							onChange(selectedOption?.value);
							setInputValue(selectedOption?.label || '');
						}}
						value={value}
						inputValue={inputValue}
						onInputChange={(e, newValue, reason) => {
							switch(reason){
								case "clear": 
									setInputValue(''); break;
								case "input": 
									setInputValue(newValue); break;
								case "reset": 
									setInputValue(getLabelFromValue(newValue)); break;
							}
							// console.log(newValue, reason)
							// setInputValue(newValue)
						}}
						renderOption={(props, option) => {
							return (
								<li
									{...props}

									key={option?.key || option.value}
									// onClick={() => {
									// 	onChange(option.value);
									// 	setInputValue(option.label)
									// }}
								>
									{option.label || 'no-label'}
								</li>
							);
						}}
						placeholder={placeholder || label}
						renderInput={(params) => {
							return <TextField {...params}  label={label || placeholder } placeholder={placeholder || label} />;
						}}
					/>
					{error && (
						<Typography color="error" variant="caption">
							{error?.message}
						</Typography>
					)}
				</Grid>
			)}
		/>
	);
	// return <TextField {...props} />;
};
