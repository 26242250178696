import type { ReactNode } from 'react';

import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import * as meterialIcons from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/DashboardTwoTone';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  type: 'a3' | 'admin'
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General A3 Owner',
    type: 'a3',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: '/a3/dashboard',
      }, {
        icon: CreditCardIcon,
        name: 'Customers',
        link: '/a3/customers'
      }, {
        icon: PersonIcon,
        name: 'Users',
        link: '/a3/users'
      },
    ]
  },
  {
    heading: 'Manage Client Company',
    type: 'a3',
    items: [

      {
        name: 'Challenges',
        icon: meterialIcons.AccessibilityNew,
        items: [
          {
            name: 'Cooperative Challenges',
            link: '/admin/corp-challenges'
          },
        ]
      },

      {
        name: 'Company\'s KPIs',
        icon: meterialIcons.BarChart,
        items: [
          {
            name: 'Manage KPIs',
            link: '/admin/kpis'
          },
        ]
      },
    ]
  },
  {
    heading: 'Manage My Company',
    type: 'admin',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: '/a3/dashboard',
      },

      {
        name: 'Challenges',
        icon: meterialIcons.AccessibilityNew,
        items: [
          {
            name: 'Cooperative Challenges',
            link: '/admin/corp-challenges'
          },
        ]
      },

      {
        name: 'Company\'s KPIs',
        icon: meterialIcons.BarChart,
        items: [
          {
            name: 'Manage KPIs',
            link: '/admin/kpis'
          },
        ]
      },
    ]
  },

];

export default menuItems;
