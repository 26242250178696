import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import authReducer, { authApiSlice } from './slices/auth/auth.slice'
import { adminUsersSlice } from './slices/adminUsers/adminUsers.slice'
import { branchesApiSlice } from './slices/branches/branches.slice';
import modalSlice from './slices/modal/modal.slice';
import snackbarSlice from './slices/snackbar/snackbar.slice';
import { challengesApi } from './slices/challenges/challenges.slice';
import { companyApiSlice, companySlice } from './slices/companies/companies.slice';
import { metricsApiSlice } from './slices/metrics/metrics.slice';
import { kpisApiSlice, kpisSlice } from './slices/kpis/kpis.slice';
import { appSlice } from './slices/app/app.slice';

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    auth: authReducer,
    modal: modalSlice,
    snackbar: snackbarSlice,
    company: companySlice.reducer,
    kpi: kpisSlice.reducer,
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [adminUsersSlice.reducerPath]: adminUsersSlice.reducer,
    [branchesApiSlice.reducerPath]: branchesApiSlice.reducer,
    [challengesApi.reducerPath]: challengesApi.reducer,
    [companyApiSlice.reducerPath]: companyApiSlice.reducer,
    [metricsApiSlice.reducerPath]: metricsApiSlice.reducer,
    [kpisApiSlice.reducerPath]: kpisApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(
      authApiSlice.middleware,
      adminUsersSlice.middleware,
      branchesApiSlice.middleware,
      challengesApi.middleware,
      companyApiSlice.middleware,
      metricsApiSlice.middleware
    )
  },
  devTools: true
});

// Types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Aliases with types
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useAppDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
