import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { User } from "src/models/user.model";
import { getToken } from "src/services/auth";
import { Challenge } from "src/models/challenge.model";
import { Company } from "src/models/company.model";
import { CompanyApi } from "src/services/companyApi";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";


interface CompanyState {
    currentCompany: Partial<Company>,
    showCompanySelector: boolean
}
let initialState: CompanyState = {
    currentCompany: null,
    showCompanySelector: true
}



export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompany(state, action: PayloadAction<Company>) {
            state.currentCompany = action.payload
        },
        unSetComapny(state) {
            state.currentCompany = null
        },
        showCompanySelector(state) {
            state.showCompanySelector = true
        },
        hideCompanySelector(state) {
            state.showCompanySelector = false
        },
    }
})

export const companyApiSlice = createApi({
    reducerPath: 'companyApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders(headers) {
            headers.set("Authorization", getToken());
            return headers
        },
    }),
    tagTypes: ['companies'],
    keepUnusedDataFor: 0,
    endpoints(builder) {
        return {
            getCompanies: builder.query<Company[], void>({
                queryFn: async () => {
                    const companiesDB = await CompanyApi.get()
                    return { data: companiesDB }
                },
                providesTags: ['companies'],
                keepUnusedDataFor: 10 * 60
            }),

        }
    }
})

export const { useGetCompaniesQuery } = companyApiSlice
export const { setCompany, unSetComapny, showCompanySelector, hideCompanySelector } = companySlice.actions