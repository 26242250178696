import { Alert, AppBar, Button, Grid, Snackbar, Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { A3Input } from 'src/components/A3Input/A3Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { ChallengesApi } from 'src/services/challengesApi';
import { A3Option } from 'src/models/types';
import { A3Autocomplete } from 'src/components/A3Autocomplete/A3Autocomplete';
import { ChallengeCategoryTypes } from 'src/types/misc';
import { closeModal, openModal } from 'src/store/slices/modal/modal.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { A3Select } from 'src/components/A3Select/A3Select';
import { ChallengeActivity } from 'src/models/app-models/challengeActivity.model';
import { ChallengeCategory } from 'src/models/app-models/challengeCategory.model';
import { A3DatePicker } from 'src/components/A3DatePicker/A3DatePicker';
import { Challenge } from 'src/models/challenge.model';
import moment from 'moment';
import { challengesApi } from 'src/store/slices/challenges/challenges.slice';

const schema = yup
    .object({
        name: yup.string().required('Set the name'),
        categoryId: yup.string().required('Select a category'),
        activityId: yup.string().required('Select a activity'),
        unit: yup.string().required('Select a unit'),
        quantity: yup.string().required('Select a quantity'),
        startsAt: yup.date().required('Select a startsAt'),
        endsAt: yup.date().required('Select a endsAt')
    })
    .required();

type CoorpChallengeFormType = {
    name: string;
    categoryId: ChallengeCategoryTypes;
    activityId: string;
    unit: string;
    quantity: string;
    startsAt: Date;
    endsAt: Date;
};

type Props = {
    challengeId?: string;
};

export const CoopChallengesEdit = ({ challengeId }: Props) => {
    const dispatch = useAppDispatch();
    const currentCompany = useAppSelector((s) => s.company.currentCompany);
    
    const [activitiesOptions, setActivitiesOptions] = useState([]);
    const [categoriesOptions, setCategoriesOptions] = useState<A3Option[]>([]);
    const [unitsOptions, setUnitsOptions] = useState([]);
    const [activities, setActivities] = useState<ChallengeActivity[]>([]);
    const [categories, setCategories] = useState<ChallengeCategory[]>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [challenge, setChallenge] = useState<Challenge>(null);
    const { handleSubmit, control, setValue } = useForm<CoorpChallengeFormType>({
        resolver: yupResolver(schema)
    });
    const fields = {
        categoryId: '',
        activityId: '',
        startsAt: new Date(),
        endsAt: new Date(),
    };

    fields.categoryId = useWatch({ control: control, name: 'categoryId' });
    fields.activityId = useWatch({ control: control, name: 'activityId' });
    fields.startsAt = useWatch({ control: control, name: 'startsAt' });
    fields.endsAt = useWatch({ control: control, name: 'endsAt' });

    const getActivities = (categoryId: ChallengeCategoryTypes) => {
        if (!categoryId) {
            setActivities([]);
            setActivitiesOptions([]);
            setUnitsOptions([]);
            setValue('unit', '');
            return;
        }
        const tempActivities = ChallengesApi.getChallengesCategoriesActivities(categoryId);
        setActivities(tempActivities);
        const activitiesOptions = tempActivities.map((c, i) => ({
            label: c.name,
            value: c.id,
            key: i
        }));
        setActivitiesOptions(activitiesOptions);
    };
    const getCategories = () => {
        const tempCategories = ChallengesApi.getChallengesCatregories();
        setCategories(tempCategories);
        const categoriesOptions = tempCategories.map((c, i) => ({
            label: c.name,
            value: c.id,
            key: i
        }));
        setCategoriesOptions(categoriesOptions);
    };
    const getUnits = () => {
        const unitsOptionsTemp: A3Option[] = activities
            .find((c) => c.id === fields.activityId)
            .units.map((u, i) => {
                if (i === 0) setValue('unit', u);
                return {
                    key: i,
                    label: u,
                    value: u
                };
            });
        setUnitsOptions(unitsOptionsTemp);
    };

    const getChallenge = async (challengeId: string) => {
        if (!challengeId) return;
        const challengeDB = await ChallengesApi.getChallenge(challengeId);
        if (!challengeDB) return;
        setChallenge(challengeDB)
        setValue('name', challengeDB.name);
        setValue('categoryId', challengeDB.challengeCategory.id);
        setValue('activityId', challengeDB.challengeActivity.id);
        setValue('unit', challengeDB.unit);
        setValue('quantity', String(challengeDB.quantity));
        setValue('startsAt', moment(challengeDB.startsAt).toDate());
        setValue('endsAt', moment(challengeDB.endsAt).toDate());
    };

    const onSubmit = (data: CoorpChallengeFormType) => {
        if (!challengeId) {
            ChallengesApi.createChallenge(
                new Challenge({
                    // console.log(new Challenge({
                    isCooperative: true,
                    startsAt: new Date(data.startsAt).getTime(),
                    endsAt: new Date(data.endsAt).getTime(),
                    name: data.name,
                    unit: data.unit,
                    quantity: Number(data.quantity),
                    challengeCategory: categories.find((c) => c.id === data.categoryId),
                    challengeActivity: activities.find((a) => a.id === data.activityId),
                    companyId: currentCompany.id,
                    type: 'Cooperative'
                })
            );
        } else {
            ChallengesApi.updateChallenge(
                new Challenge({
                    ...challenge,
                    id:challengeId,
                    isCooperative: true,
                    startsAt: new Date(data.startsAt).getTime(),
                    endsAt: new Date(data.endsAt).getTime(),
                    name: data.name,
                    unit: data.unit,
                    quantity: Number(data.quantity),
                    challengeCategory: categories.find((c) => c.id === data.categoryId),
                    challengeActivity: activities.find((a) => a.id === data.activityId),
                    type: 'Cooperative'
                })
            );
        }
        dispatch(challengesApi.util.invalidateTags(['challenges']))
        dispatch(closeModal());
        setOpenSnackbar(true)
    };
    const handleCancel = () => {
        dispatch(closeModal());
    };

    useEffect(() => {
        getCategories();
        if (challengeId) getChallenge(challengeId);
    }, []);

    useEffect(() => {
        getActivities(fields.categoryId as ChallengeCategoryTypes);
    }, [fields.categoryId]);

    useEffect(() => {
        if (!fields.activityId) return;
        getUnits();
    }, [fields.activityId]);

    return (
        <>
            <Grid container direction="column" p={3} pb={0}>
                <Grid item>
                    <Typography variant="h3" mb={2}>
                    {challengeId ? 'Update the Cooperative Challenge' : 'Create a Cooperative Challenge'}

                    </Typography>
                </Grid>
                <Grid container direction="column">
                    <Grid container direction="row">
                        <Grid item xs={12} container direction="column" mt={2}>
                            <A3Input label="Challenge Name" control={control} name="name" />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6} container direction="column" mt={2}>
                            <A3Autocomplete
                                options={categoriesOptions}
                                label="Select a Category"
                                placeholder="Select a Category"
                                control={control}
                                name="categoryId"
                            />
                        </Grid>
                        <Grid item xs={6} container direction="column" mt={2}>
                            <A3Autocomplete options={activitiesOptions} label="Activity" control={control} name="activityId" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h4" my={3}>
                            What will be the challenge?
                        </Typography>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <A3Input type="number" control={control} name="quantity" placeholder="How many" label="How Many" />
                        </Grid>
                        <Grid item xs={3}>
                            <A3Select control={control} name="unit" label="Unit" options={unitsOptions} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h4" my={3}>
                            How long will it be?
                        </Typography>
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <A3DatePicker disablePast control={control} name="startsAt" label="Starts At" />
                        </Grid>
                        <Grid item xs={6}>
                            <A3DatePicker minDate={fields.startsAt} control={control} name="endsAt" label="Ends At" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ position: 'relative', right: 0, bottom: '0px', p: 2 }}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <Button sx={{ ml: 2 }} variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button sx={{ ml: 2 }} variant="outlined" color="success" onClick={handleSubmit(onSubmit)}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
};
