
import { TextField, Typography } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import {BaseDateValidationProps } from '@mui/x-date-pickers/internals/hooks/validation/models';
import { Controller, Control } from 'react-hook-form';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

type Props = {
	control: Control<any, unknown>;
	name: string;
	defaultValue?: string;
	label?: string;
} & BaseDateValidationProps<any>
export const A3DatePicker = ({ control, name, defaultValue = '', label = '', ...rest }: Props) => {
	if (!name) console.error('A3DatePicker: you have to set a unique name');
	return (
		<LocalizationProvider dateAdapter={AdapterLuxon}>
			<Controller
				control={control}
				defaultValue={defaultValue}
				name={name}
				render={({ field: { ref, onBlur, name, ...field }, fieldState: { error } }) => (
					<DesktopDatePicker
						{...field}
						{...rest}
						onChange={(val: DateTime) => {
							field.onChange(val.toJSDate());
						}}
						inputRef={ref}
						label={label}
						renderInput={(inputProps) => (
							<>
								<TextField
									{...inputProps}
									onBlur={onBlur}
									name={name}
									disabled
									error={!!error}
									sx={{width:'100%'}}
								/>
								{error && (
									<Typography color="error" variant="caption">
										{error?.message}
									</Typography>
								)}
							</>
						)}
						disableMaskedInput
					/>
				)}
			/>
		</LocalizationProvider>
	);
};
