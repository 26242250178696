import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getToken } from "src/services/auth";
import { MetricsApi } from "src/services/metricsApi";
import { Metrics } from "src/models/metrics";

export const metricsApiSlice = createApi({
    reducerPath: 'metricsApiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        prepareHeaders(headers) {
            headers.set("Authorization", getToken());
            return headers
        },
    }),
    tagTypes: ['metrics'],
    keepUnusedDataFor: 10*60*60,
    endpoints(builder) {
        return {
            getMetrics: builder.query<Metrics, {companyId: string}>({
                queryFn: async ({companyId}) => {
                    const metricsDB = await MetricsApi.get(companyId)
                    return { data: metricsDB }
                },
                providesTags: ['metrics'],
                keepUnusedDataFor: 0
            }),

        }
    }
})

export const { useGetMetricsQuery } = metricsApiSlice
