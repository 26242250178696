import { PartialRouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';

import { a3Routes } from './a3';
import { adminRoutes } from './admin';
import accountRoutes from './account';

const router: PartialRouteObject[] = [
    {
        path: 'account',
        children: accountRoutes
    },
    // Extended Sidebar Layout
    {
        path: '*',
        element: (
            <Authenticated>
                <ExtendedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/a3',
                children: a3Routes
            },
            {
                path: '/admin',
                children: adminRoutes
            },
            {
                path: '/',
                element: <Navigate to="/a3/dashboard" replace />
            }
        ]
    }
];

export default router;
