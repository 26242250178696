import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { A3SnackbarOptions } from "src/components/A3Snackbar/A3Snackbar.types";


interface A3SnackbarState {
    snackbarOptions: Partial<A3SnackbarOptions>,
}
const initialState: A3SnackbarState = {
    snackbarOptions: {
        isOpen: false,
    },
}

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar(state, action: PayloadAction<A3SnackbarOptions>) {
            state.snackbarOptions = action.payload
            state.snackbarOptions.isOpen = true
        },
        closeSnackbar(state) {
            state.snackbarOptions = {
                isOpen: false
            };
        },
    }
})

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions
export default snackbarSlice.reducer