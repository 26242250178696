import { useRoutes } from 'react-router-dom';
import routes from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { useAppDispatch, useAppSelector } from './store';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setUser, setError, useSignInQuery, logout } from './store/slices/auth/auth.slice';
import { useEffect, useState } from 'react';
import { signOutFirebase } from './providers/firebase';
import { A3Modal } from './components/A3Modal/A3Modal';
import { A3Snackbar } from './components/A3Snackbar/A3Snackbar';
import { setCompany, unSetComapny } from './store/slices/companies/companies.slice';
import { CompanyApi } from './services/companyApi';
import { openModal } from './store/slices/modal/modal.slice';

const App = () => {
    const router = useRoutes(routes);

    const isInitialized = useAppSelector((s) => s.auth.isInitialized);
    const currentCompany = useAppSelector((s) => s.company.currentCompany);

    const [firebaseToken, setFirebaseToken] = useState(null);
    const dispatch = useAppDispatch();
    const {
        data: userDB,
        isError: isAuthError,
        error: authError,
        isLoading
    } = useSignInQuery({ firebaseToken }, { skip: !firebaseToken });

    const getCompany = async (companyId: string) => {
        if (!companyId) return;
        const companyDB = await CompanyApi.getById(companyId);
        if (!companyDB?.id) {
            dispatch(
                openModal({
                    title: 'Error',
                    body: "Company doesn't exits, please check with support.",
                    height: '40%',
                    onClose: () => {
                        dispatch(logout());
                    }
                })
            );
        }

        dispatch(setCompany(companyDB));
    };

    useEffect(() => {
        if (userDB?.id) {
            dispatch(setUser(userDB));
            getCompany(userDB.companyId);
        }
    }, [userDB]);

    useEffect(() => {
        if (isAuthError) {
            console.log({ authError });

            dispatch(setError(`Couldn't authenticate in A3 Challenges Admin`));
            signOutFirebase();
        }
    }, [authError, isAuthError]);

    const auth = getAuth();
    useEffect(() => {
        const unsubscribeFromObserver = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                dispatch(setError(null));
                const newFirebaseToken = await firebaseUser.getIdToken(true);
                setFirebaseToken(newFirebaseToken);
            } else {
                dispatch(setUser(null));
                dispatch(unSetComapny());
            }
        });
        return () => {
            unsubscribeFromObserver();
        };
    }, []);

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <CssBaseline />
                    {isInitialized && !isLoading ? router : <AppInit />}
                </SnackbarProvider>
                <A3Modal type="confirmation" />
                <A3Modal type="modal" />
                <A3Snackbar />
            </LocalizationProvider>
        </ThemeProvider>
    );
};
export default App;
